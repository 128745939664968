<template>
  <div class="manager-container">
    <div class="button-block">
      <div>
        <div class="query-item">
          <span>{{ $t('email') }}</span>
          <el-input
            size="mini"
            :placeholder="$t('pleaseEnterContent')"
            v-model="queryFormData.email">
          </el-input>
        </div>
        <div class="query-item">
          <span>{{ $t('operationTime') }}</span>
          <el-date-picker
            v-model="queryFormData.operateDate"
            type="datetimerange"
            size="mini"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            :start-placeholder="$t('startDate')"
            :end-placeholder="$t('endDate')">
          </el-date-picker>
        </div>
        <el-button
          type="primary"
          size="mini"
          @click="query">
          {{ $t('query') }}
        </el-button>
        <el-button
          size="mini"
          @click="reset">
          {{ $t('reset') }}
        </el-button>
      </div>
    </div>
    <div class="table-block">
      <el-table
        :border="true"
        :header-cell-style="{...HEADER_CELL_STYLE,textAlign: 'left' }"
        :cell-style="{...CELL_STYLE,textAlign: 'left' }"
        v-loading="tableLoading"
        :data="tableData"
        height="100%"
        stripe>
        <base-no-data slot="empty"></base-no-data>
        <el-table-column
          type="index"
          :label="$t('serialNum')"
          width="50">
        </el-table-column>

        <el-table-column
          prop="tableName"
          :label="$t('tableName')"
          align="center"
          >
        </el-table-column>

        <el-table-column
          prop="tableDesc"
          :label="$t('tableDesc')"
          align="center"
          >
        </el-table-column>

        <el-table-column
          prop="businessId"
          align="center"
          :label="$t('businessId')"
          show-overflow-tooltip
          >
        </el-table-column>

        <el-table-column
          :label="$t('desc')"
          width="600">
          <template slot-scope="scope">
            <el-tag size="mini">{{ scope.row.fieldName }}</el-tag>
            <span>{{ $i18n.locale === 'cn' ? ' 从 ' : ' change from ' }}</span>
            <el-tag size="mini">{{ scope.row.oldValue }}</el-tag>
            <span>{{ $i18n.locale === 'cn' ? ' 变更为 ' : ' to ' }}</span>
            <el-tag size="mini">{{ scope.row.newValue }}</el-tag>
          </template>
        </el-table-column>

        <!--操作人姓名-->
        <el-table-column
          prop="name"
          :label="$t('operator')"
          align="center"
          show-overflow-tooltip
         >
        </el-table-column>

        <!--操作人邮箱-->
        <el-table-column
          prop="email"
          :label="$t('email')"
          align="center"
          show-overflow-tooltip
          >
        </el-table-column>

        <el-table-column
          prop="createTime"
          :label="$t('operateDate')"
          align="center"
          width="180">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        small
        @size-change="handleSizeChangeMixin"
        @current-change="handleCurrentChangeMixin"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :current-page="pageIndex"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import { HEADER_CELL_STYLE, CELL_STYLE } from '@/constants.js'
export default {
  name: 'dataChangeLogging',
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      queryUrl: '',
      queryFormData: {
        email: '',
        operateDate: []
      }
    }
  },
  created () {
    this.queryUrl = this.$apis.queryDataChangeLog
    this.getTableDataMixin()
  },
  methods: {
    query () {
      /**
       * 高级查询
       */
      this.pageIndex = 1
      this.conditions = []
      if (this.queryFormData.email) {
        this.conditions.push({
          calculator: 'like',
          connector: 'AND',
          fieldName: 'email',
          value: this.queryFormData.email
        })
      }
      if (this.queryFormData.operateDate?.length) {
        this.conditions.push({
          calculator: '>=',
          connector: 'AND',
          fieldName: 'createTime',
          value: this.queryFormData.operateDate[0]
        })
        this.conditions.push({
          calculator: '<=',
          connector: 'AND',
          fieldName: 'createTime',
          value: this.queryFormData.operateDate[1]
        })
      }
      if (this.conditions.length) {
        this.$request.post({
          url: this.queryUrl,
          data: {
            queries: this.conditions
          }
        }).then(data => {
          if (data?.code === 1000) {
            this.tableData = data.data.records
            this.total = data.data.total
            const msg = `${this.$t('querySuccess_1')}${this.total}${this.$t('querySuccess_2')}`
            this.$message.success(msg)
          }
        })
      }
    },

    reset () {
      /**
       * 重置查询条件
       */
      this.pageIndex = 1
      // 重置高级查询条件
      this.conditions = []
      this.queryFormData = this.$options.data().queryFormData
      this.getTableDataMixin()
    },

    refreshData () {
      /**
       * 刷新数据
       */
      this.pageIndex = 1
      this.conditions = []
      this.queryFormData = this.$options.data().queryFormData
      this.refreshDataMixin()
    }
  }
}
</script>

<style lang="scss" scoped>
.manager-container {
  height: 100%;
  width: 100%;

  .table-block {
    height: calc(100% - 114px);
  }


  .button-block {
    @include flex-center-row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;

    .query-item {
      display: inline-flex;
      align-items: center;
      margin: 10px 20px;

      span {
        display: inline-block;
        word-break: keep-all;
        margin-right: 10px;
        flex: 5em;
      }
    }
  }
}
</style>
